// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../utils/I18n.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as OpenaiIcon from "./OpenaiIcon.bs.js";

var partial_arg = "zamn_shared";

function tz(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

var generateWithAI = (function(prompt,handleResponse,handleResponseError) {
    let description_generator=""
    const eventSource = new EventSource("/openai?q="+prompt)
    eventSource.addEventListener('token', ({ data }) => {

      if(data === 'StopEventStreamOpenAi') {
        eventSource.close()
      } else {
        description_generator+=decodeURIComponent(data).replace('+', ' ')
        handleResponse(description_generator)
      }
    });

    eventSource.addEventListener('error', (errorEvent)=>{
        eventSource.close()
        handleResponseError(decodeURIComponent(errorEvent.data.replaceAll("+",' ')))
    })
});

function ModalOpenai$Alert(Props) {
  var error = Props.error;
  return React.createElement("div", {
              className: "border-l-4 border-red-400 bg-red-50 p-4"
            }, React.createElement("div", {
                  className: "flex"
                }, React.createElement("div", {
                      className: "flex-shrink-0"
                    }, React.createElement("svg", {
                          className: "h-5 w-5 text-red-400",
                          viewBox: "0 0 20 20"
                        }, React.cloneElement(React.createElement("path", {
                                  d: "M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                                }), {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd"
                            }))), React.createElement("div", {
                      className: "ml-3"
                    }, React.createElement("p", {
                          className: "text-sm text-red-700"
                        }, error))));
}

var Alert = {
  make: ModalOpenai$Alert
};

function ModalOpenai(Props) {
  var show = Props.show;
  var onClose = Props.onClose;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return "";
      });
  var setPrompt = match[1];
  var prompt = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setResponse = match$1[1];
  var response = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setError = match$2[1];
  var error = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setOnLoading = match$3[1];
  var onLoading = match$3[0];
  var style = show ? "flex" : "none";
  var handleResponse = function (data) {
    Curry._1(setError, (function (param) {
            return "";
          }));
    Curry._1(setOnLoading, (function (param) {
            return false;
          }));
    Curry._1(setResponse, (function (param) {
            return data;
          }));
  };
  var handleResponseError = function (error) {
    Curry._1(setError, (function (param) {
            return error;
          }));
    Curry._1(setPrompt, (function (param) {
            return "";
          }));
    Curry._1(setResponse, (function (param) {
            return "";
          }));
    Curry._1(setOnLoading, (function (param) {
            return false;
          }));
  };
  var loadData = function (value) {
    Curry._1(setOnLoading, (function (param) {
            return true;
          }));
    return generateWithAI(value, handleResponse, handleResponseError);
  };
  var tmp = error === "" ? null : React.createElement(ModalOpenai$Alert, {
          error: error
        });
  return React.createElement("div", {
              className: "fixed top-0 left-0 w-full h-full bg-opacity-50 blanket hidden items-center z-10 justify-center",
              style: {
                display: style
              }
            }, React.createElement("div", {
                  className: "bg-white rounded-lg shadow-md w-3/4"
                }, React.createElement("div", {
                      className: "flex p-5 pb-3 items-center justify-between border-b-4"
                    }, React.createElement("b", {
                          className: "text-xl"
                        }, tz(undefined, undefined, "description_generator")), React.createElement("button", {
                          className: "text-lg",
                          type: "button",
                          onClick: (function (param) {
                              Curry._1(onClose, undefined);
                            })
                        }, "X")), React.createElement("div", {
                      className: "p-5 "
                    }, React.createElement("div", {
                          className: "mt-5"
                        }, React.createElement("label", {
                              className: "inline-block tracking-wide text-xs font-semibold",
                              htmlFor: "description"
                            }, tz(undefined, undefined, "label")), React.createElement("input", {
                              className: "appearance-none block w-full bg-white border border-gray-300 rounded py-3 px-4 mt-2 leading-tight focus:outline-none focus:bg-white focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                              id: "description",
                              maxLength: 150,
                              placeholder: tz(undefined, undefined, "placeholder"),
                              type: "text",
                              value: prompt,
                              onKeyDown: (function ($$event) {
                                  if ($$event.key === "Enter") {
                                    return loadData(prompt);
                                  }
                                  
                                }),
                              onChange: (function ($$event) {
                                  Curry._1(setPrompt, $$event.target.value);
                                })
                            })), React.createElement("div", {
                          className: "mt-5"
                        }, response), tmp), React.createElement("div", {
                      className: "mt-5 px-5 py-3 border-t-2"
                    }, React.createElement("div", {
                          className: "flex"
                        }, React.createElement("div", undefined, React.createElement("button", {
                                  className: "btn btn-primary",
                                  disabled: onLoading,
                                  onClick: (function (param) {
                                      loadData(prompt);
                                    })
                                }, React.createElement(OpenaiIcon.OpenaiIcon.make, {
                                      color: "#fff",
                                      class: "mx-1",
                                      width: "12",
                                      height: "12"
                                    }), response.length !== 0 ? tz(undefined, undefined, "re_generate_description") : tz(undefined, undefined, "generate_description")), React.createElement("button", {
                                  className: "btn btn-success mx-3",
                                  disabled: response.length === 0 || onLoading,
                                  onClick: (function (param) {
                                      Curry._1(onChange, response);
                                      Curry._1(setPrompt, (function (param) {
                                              return "";
                                            }));
                                      Curry._1(setResponse, (function (param) {
                                              return "";
                                            }));
                                      Curry._1(onClose, undefined);
                                    })
                                }, tz(undefined, undefined, "copy"))), React.createElement("div", {
                              className: "ms-auto"
                            }, React.createElement("button", {
                                  className: "btn btn-secondary",
                                  type: "button",
                                  onClick: (function (param) {
                                      Curry._1(onClose, undefined);
                                    })
                                }, tz(undefined, undefined, "close")))))));
}

var ts = I18n.ts;

var make = ModalOpenai;

export {
  tz ,
  ts ,
  str ,
  generateWithAI ,
  Alert ,
  make ,
}
/* I18n Not a pure module */
